import cn from 'classnames';
import React, {useEffect, useRef} from 'react';

import Image from '@/components/common/image/Image';
import {useMediaQueryMatch} from '@/hooks/use-media-query-match';

import styles from '../../Companies.module.css';
import {ICard} from '../../const';
interface ILogoCarouselProps {
	cards: ICard[];
	isLight: boolean;
}
export function LogoCarousel({cards, isLight}: ILogoCarouselProps) {
	const [isDesktop] = useMediaQueryMatch('(min-width: 1440px)');
	const carouselRef = useRef(null);
	const logos = cards.map(({imgLight, imgDark}) => (isLight ? imgLight : imgDark));
	const gap = isDesktop ? 11 : 8;

	useEffect(() => {
		const scrollInterval = setInterval(() => {
			if (carouselRef.current) {
				carouselRef.current.style.left = `${carouselRef.current.offsetLeft - 1}px`;

				const firstItem = carouselRef.current.firstElementChild;
				const wFirstItem = firstItem.clientWidth;
				if (carouselRef.current.offsetLeft <= wFirstItem * -2) {
					carouselRef.current.appendChild(firstItem.cloneNode(true));

					carouselRef.current.removeChild(firstItem);
					carouselRef.current.style.left = `${carouselRef.current.offsetLeft + wFirstItem + gap}px`;
				}
			}
		}, 20);

		return () => clearInterval(scrollInterval);
	}, [gap]);

	return (
		<div className={styles.carousel}>
			<div className={styles.cards} ref={carouselRef}>
				{logos.map((logo, index) => (
					<div
						key={index}
						className={cn(styles.card, {
							[styles.cardDark]: !isLight,
							[styles.cardLight]: isLight,
						})}
					>
						<Image
							src={logo}
							alt="logo"
							layout="fill"
							objectFit={isDesktop ? 'none' : 'contain'}
							className={styles.logo}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
